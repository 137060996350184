var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('page',[_vm._l((_vm.transformedPointsByObject),function(pointsByObject,i){return _c('x-data-table',{key:i,attrs:{"headers":_vm.headers,"items":pointsByObject.pointList,"caption":i,"use-caption-as-index":true,"use-item-slot":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item",fn:function({ item: { item } }){return [_c('tr',{staticClass:"data-table__text",on:{"click":() => {
            const point = _vm.sortedPointsByObject[i].pointList.find(
              (point) => point['номер'].toString() === item.deviceId.toString()
            );

            _vm.chooseAccountingPoint(point, () => _vm.$emit('update:slider', true));
            _vm.openPlanByAccountingPoint(item);
          }}},_vm._l((item),function(value){return _c('td',{key:value,attrs:{"title":value}},[_vm._v(_vm._s(value))])}),0)]}}],null,true)})}),_c('x-slider',{attrs:{"show":_vm.showPlanByAccountingPoint,"container-mod-class":"split-container_width_max","box-mod-class":"split-container__main_with_card","title":`План по точке учета № ${_vm.chosenPointId}, ${_vm.address}`},on:{"update:show":function($event){_vm.showPlanByAccountingPoint=$event},"close":_vm.closePlanByAccountingPoint}},[_c('accounting-point-plan-card',{attrs:{"showSlider":_vm.showPlanByAccountingPoint},on:{"close-slider":_vm.closePlanByAccountingPoint}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }